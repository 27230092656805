<template>
  <div class="conditions-generales content">
    <section id="page-title" class="page-title-mini">
      <div class="container clearfix">
        <h1>{{ $t('legal.conditions-generales.title') }}</h1>
      </div>
    </section>
    <div class="content-wrap">
      <div class="container clearfix">
        <Fragment path="conditions-generales"></Fragment>
      </div>
    </div>
  </div>
</template>

<style>

</style>

<script lang="ts">
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import Fragment from '@fwk-client/components/panels/Fragment.vue';

@Component({
  components: {
	  Fragment
  },
  computed: {
	  
  }
})
export default class ConditionsGenerales extends mixins(GenericPage) {

	

}
</script>